@import '../../css/variables';
.video-popup {
	background: $white;
	z-index: 999999;
	.popup-header {
		width: 100%;
		padding: $p4;
		height: 87px;
		.back-btn {
			color: $text;
			font-weight: bold;
			font-size: $f4;
			i {
				margin-right: $m1;
				font-size: $f6;
			}
		}
	}
	.page-ctn {
		padding: 16px;
		.video {
			width: 100%;
		}
		iframe {
			height: 400px;
			width: 100%;
			border-radius: 12px;
			box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px $primary;
		}
	}
}
