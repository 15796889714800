@import '../../css/variables.scss';
.progress-page-cmp {
	font-family: $pop;
	background: $white;
	.color-blue {
		a {
			background: #bce9fe;
			color: $primary;
		}
	}
	.background {
		background: linear-gradient(180deg, rgba(26, 156, 255, 0) 75%, $bgGradiantLight 90%, $bgGradiantDark 100%);
		position: fixed;
		z-index: -1;
		min-height: 900px;
		width: 100%;
		height: 100%;
	}

	.content-ctn {
		animation-duration: 0.3s;
		height: 100%;
		max-height: calc(100vh - 75px);
		.bg-image {
			max-width: 630px;
			width: 100%;
			z-index: -1;
		}
		h1 {
			letter-spacing: -1px;
			text-align: center;
			margin: 0;
			font-weight: 600;
			font-size: $f7;
			margin-bottom: $m4;
			transition: 0.2s;
		}
		.divider-line {
			height: 2px;
			width: 100%;
			max-width: 320px;
			background: #e5e5e5;
			margin: 0 auto;
			margin-bottom: $m6;
		}
		.empty-message {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			color: $primary;
			margin-bottom: 100px;
			.add-goal-btn {
				background: $white;
			}
			.image-ctn {
				width: 120px;
				height: 120px;
				border-radius: 50%;
				background: #eef9ff;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				img {
					width: 72px;
				}
			}
			h2 {
				font-weight: 600;
				font-size: 22px;
				margin-bottom: 16px;
			}
			p {
				font-weight: 500;
				font-size: 14px;
				margin: 0;
				text-align: center;
				max-width: 280px;
				margin-bottom: $m4;
			}
		}

		.spacer {
			height: 50px;
		}
	}

	&.desktop {
		.page-content {
			overflow-y: scroll;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch !important;
		}
		.content-ctn {
			max-width: 630px;
			margin-left: auto;
			margin-right: auto;
			.header-ctn {
				margin-bottom: 320px;
			}
			.spacer {
				height: 200px;
			}
			.goal-card {
				.goal-body {
					width: 100%;
					.text-body {
						width: 100%;
						margin-right: 20px;
					}
				}
			}
		}
	}
}
