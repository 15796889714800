@import '../../css/variables.scss';
.app-form-builder {
	.app-dropdown {
		margin-bottom: $m4;
		//height: 55px;
		.label {
			font-family: $pop;
			color: $primary;
			font-size: 14px;
			margin-bottom: 3px;
			margin-left: 16px;
			font-weight: 500;
		}
		.gradiant {
			background: linear-gradient(90deg, $buttonLight 0%, $buttonDark 100%);
			border-radius: 12px;
			height: 44px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			.selected-value {
				border-radius: 10px;
				margin-bottom: 0;
				min-height: 40px;
				max-height: 40px;
				background: $white;
				width: calc(100% - 4px);
				i {
					font-size: $f6;
					color: $buttonDark;
				}
				.fa-chevron-down {
					margin: 0;
				}
				div {
					color: $greyText !important;
					font-size: $f3;
					font-weight: 500;
				}
			}
		}
	}
	.basic-input {
		.label {
			font-size: $f2;
		}
		margin-bottom: $m4;
		.input-error-message {
			visibility: hidden;
			margin-top: $m1;
			margin-left: $m4;
		}
		.input-with-infom,
		.input-with-error-message {
			padding-bottom: 0 !important;
			//margin-bottom: $m7 !important;
		}
		&.basic-date-input {
			cursor: pointer;
			input {
				cursor: pointer;
			}
		}
		i {
			position: absolute;
			z-index: 1;
			right: 20px;
			font-size: $f5;
			color: $primary;
			cursor: pointer !important;
		}
	}
	.form-row {
		.basic-input {
			&:first-child {
				margin-right: $m4;
			}
		}
		.app-dropdown {
			&:first-child {
				margin-right: $m4;
			}
		}
	}
	.app-form-toggle {
		margin-bottom: $m4;
		padding: $p3;
		border: 1px solid $borderLight;
		border-radius: $br1;
		.label {
			color: $greyText;
			font-weight: 600;
			font-size: $f2;
			flex-grow: 1;
		}
	}
	.multiselect-form-selector {
		margin-bottom: $m4;
		.label {
			color: $greyText;
			margin-bottom: 4px;
			margin-left: 4px;
			font-weight: 600;
			font-size: $f2;
		}
		ul {
			border-radius: $br2;
			box-shadow: $cardShadow;
		}
		.list {
			margin: 0;
		}
	}
}

.formbuilder-dropdown-internalGroupId,
.formbuilder-dropdown-internalPlanId,
.formbuilder-dropdown-state,
.formbuilder-dropdown-memberDataType {
	min-width: 350px;
	.popover-inner {
		min-width: 350px;
	}
	.list {
		max-height: 400px;
	}
}
