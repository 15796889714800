@import '../../css/variables';

.support-page-popup {
	background: $white;
	.popup-header {
		width: 100%;
		padding: $p4;
		height: 87px;
		.back-btn {
			color: $primary;
			font-weight: bold;
			font-size: $f4;
			i {
				margin-right: $m1;
				font-size: $f6;
			}
		}
		img {
			width: 200px;
			right: -75px;
			position: absolute;
		}
	}
	.main-content {
		padding: $p4;
		padding-top: 0;
		height: calc(100% - 87px);
		h1 {
			color: $primary;
			margin-top: 0;
			font-weight: 600;
			font-size: $f7;
			margin-bottom: $m8;
		}
		.support-btn {
			border: 1px solid $text;
			border-radius: 12px;
			padding: $p7;
			margin: $m4;
			cursor: pointer;
			color: $text;
			transition: 0.3s;
			font-weight: bold;
			i {
				font-size: $f6;
				margin-right: $m2;
			}
			&:hover {
				background-color: $primary;
				color: $white;
				border-color: $primary;
			}
		}
	}
}
