@import '../../css/_variables';

.dropdown-component {
	width: 100%;
	.label {
		color: $greyText;
		margin-bottom: 4px;
		margin-left: 4px;
		font-weight: bold;
		font-size: 16px;
	}
	.selected-value {
		border-radius: 12px;
		width: 100%;
		padding: 8px 16px 8px 16px;
		font-size: 18px;
		text-align: left;
		margin-bottom: 16px;
		background: $bg;
		font-weight: bold;
		color: $greyText;
		.ellipse {
			text-transform: capitalize;
		}
		.capitalize {
			text-transform: capitalize !important;
		}
		i {
			color: $primary;
		}
		h1 {
			color: $greyText;
		}
	}
}
