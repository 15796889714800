@import '../../css/variables';
.checkout-sheet-cmp {
	border-radius: 12px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	height: auto;
	.checkout-content {
		padding: $p4;
		max-height: 100vh;
		.loading {
			padding: $p4;
			margin-bottom: $m4;
		}
		.checkout-header {
			margin-bottom: $m5;
			a {
				color: $primary;
				font-weight: bold;
				font-size: $f2;
			}
			.title {
				font-size: $f7;
				font-weight: bold;
				color: $primary;
				width: calc(100% - 88px);
				text-align: center;
				font-family: $pop;
			}
		}
		.product-list {
			border-bottom: 1px solid #d9d9d9;
			margin-bottom: $m8;
			.product {
				padding: $p4;
				img {
					height: 56px;
					margin-right: $m3;
				}
				.product-details {
					color: $primary;
					.name {
						font-size: $f5;
						font-weight: bold;
					}
					.price {
						font-size: $f3;
					}
					.details {
						font-size: $f1;
					}
				}
			}
		}
		.payment-info {
			border-bottom: 1px solid #d9d9d9;
			margin-bottom: $m4;
			h1 {
				color: $primary;
				font-size: $f5;
			}
			.capture-btn {
				cursor: pointer;
				padding: $p4;
				border-radius: 12px;
				background-color: #b0dffb88;
				margin-bottom: $m4;
				font-size: $f3;
				font-weight: bold;
				color: $primary;
				height: 60px;
				opacity: 0.7;
				transition: 0.3s;
				.address-text {
					padding-right: $p4;
				}
				.add-icon {
					background: #64c6ff;
					height: 44px;
					width: 44px;
					border-radius: 50%;
					i {
						font-size: $f7;
						color: $primary;
					}
				}
				&.captured {
					opacity: 1;
					text-transform: capitalize;
					font-size: $f2;
					.bx-credit-card {
						font-size: $f6;
						margin-right: $m2;
					}
					.bxs-discount {
						font-size: $f6;
						margin-right: $m2;
					}
					.code {
						max-width: 200px;
					}
					.add-icon {
						i {
							font-size: $f6;
						}
					}
				}
				&:hover {
					opacity: 1;
				}
			}
			.payment-capture {
				.capture-controls {
					padding: $p4;

					.button {
						color: $primary;
					}
				}
			}
			.promo-code-form {
				.basic-input {
					margin-bottom: 0;
				}
				.capture-controls {
					padding: $p4;

					.button {
						color: $primary;
					}
				}
			}
			.shipping-form {
				.loader-ctn {
					margin-bottom: $m6;
				}
				.address-input {
					margin-bottom: $m6;
					i {
						color: $text;
						font-size: $f5;
					}
				}
				.select-text {
					font-size: $f1;
					color: $text;
					text-align: center;
					margin: 0 auto;
					margin-bottom: $m4;
					margin-top: -$m2;
					font-weight: 600;
				}
				.address-card {
					cursor: pointer;
					padding: $p4;
					background: $white;
					margin-bottom: $m4;
					font-size: $f3;
					height: 60px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 12px;
					border: 1px solid $gray-100;
					box-shadow: $cardShadow;
					transition: 0.3s;
					&:hover {
						background: $text;
						color: $white;
					}
				}
				.capture-controls {
					padding: $p4;
					.button {
						color: $primary;
					}
				}
			}
		}
		.totals {
			margin-bottom: $m8;
			.subtotals {
				border-bottom: 1px solid #d9d9d9;
				margin-bottom: $m4;
				color: $primary;
				font-size: $f3;
				padding-bottom: $p4;
				opacity: 0.75;
				.price {
					&.discount {
						color: $red-500;
					}
				}
			}
			.total {
				font-weight: bold;
				color: $primary;
				font-size: $f5;
			}
		}
		.btn-ctn {
			height: 70px;
		}
	}
	&.mobile {
		.sheet-modal-inner {
			overflow-y: scroll;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch !important;
		}
	}
	&.desktop {
		max-width: 630px;
		margin-left: calc((100vw - 630px) / 2);
		margin-top: $m10;
		border-radius: 12px;
		.checkout-content {
			.payment-info {
				max-height: 300px;
			}
		}
	}
}
