@import '../../css/variables';

.purchase-history-popup {
	background: $white;
	.popup-header {
		width: 100%;
		padding: $p4;
		height: 87px;
		.back-btn {
			color: $primary;
			font-weight: bold;
			font-size: $f4;
			i {
				margin-right: $m1;
				font-size: $f6;
			}
		}
		img {
			width: 200px;
			right: -75px;
			position: absolute;
		}
	}
	.main-content {
		padding: $p4;
		padding-top: 0;
		height: calc(100% - 87px);
		h1 {
			color: $primary;
			margin-top: 0;
			font-weight: 600;
			font-size: $f7;
		}
		.preloader {
			margin: $m8;
		}
		.empty-msg {
			text-align: center;
			color: $primary;

			.empty-svg {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				svg {
					width: 200px;
				}
			}
		}
		.invoice {
			width: 100%;
			padding: $p4;
			border-radius: 12px;
			margin-bottom: $m8;
			border: 1px dashed $coolGray-200;
			cursor: pointer;
			transition: 0.3s;
			&:hover {
				background-color: $blue-100;
			}
			.details {
				width: 100%;
				.product {
					font-weight: bold;
				}
				.status {
					&.paid {
						color: $green-700;
					}
				}
			}
			.amount {
				font-weight: bold;
				font-size: $f4;
			}
		}
	}
}
