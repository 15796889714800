@import '../../css/variables.scss';
.bvr-landing-page-cmp {
	font-family: $pop;
	background: $white;

	.background {
		background: linear-gradient(180deg, rgba(26, 156, 255, 0) 75%, $bgGradiantLight 90%, $bgGradiantDark 100%);
		position: fixed;
		z-index: -1;
		width: 100%;
		min-height: 900px;
		height: 100%;
	}

	.content-ctn {
		animation-duration: 0.3s;
		height: 100%;
		max-height: calc(100vh - 75px);
		.bg-image {
			max-width: 630px;
			width: 100%;
			z-index: -1;
		}
		.header-ctn {
			padding: 40px $p4 60px $p4;
			transition: 0.15s;
			.title {
				font-size: $f6;
				font-weight: 600;
				color: $white;
				text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.55);
				img {
					width: 44px;
					margin-right: $m4;
				}
			}
			.settings-btn {
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				background: rgba(79, 146, 199, 0.2);
				border: 2px solid #fff;
				border-radius: 50%;
				width: 44px;
				height: 44px;
				i {
					font-size: $f7;
					color: $white;
				}
			}
		}
		.spacer {
			height: 50px;
		}
		.divider-line {
			height: 2px;
			width: 100%;
			max-width: 320px;
			background: #e5e5e5;
			margin: 0 auto;
			margin-bottom: $m3;
		}
		.profile-ctn {
			padding: $p4;
			.user-icon {
				background: rgba(79, 146, 199, 0.73);
				width: 64px;
				height: 64px;
				border-radius: 50%;
				color: $white;
				font-size: $f8;
			}
			.user-name {
				font-size: $f7;
				font-weight: 600;
				text-transform: capitalize;
			}
		}

		.action-ctn {
			padding: 0 $p4 $p4 $p4;
			.section-title {
				font-weight: 600;
				font-size: $f3;
				color: $text;
				margin-bottom: $m4;
			}
			.ari-message {
				padding: $p4 $p4 $p7 $p4;
			}
			.thought-cta {
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
				border-radius: $primaryRadius;
				padding: $p2 $p4;
				color: $text2;
				font-weight: 600;
				font-size: $f3;
				cursor: pointer;
				transition: 0.2s;
				margin-bottom: $m8;

				&:hover {
					box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
				}
				.plus-icon {
					background: rgba(188, 233, 254, 0.25);
					height: 44px;
					width: 44px;
					border-radius: 50%;
					i {
						font-size: $f7;
						color: $primary;
					}
				}
			}
			.empty-message {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				color: $primary;
				.image-ctn {
					width: 120px;
					height: 120px;
					border-radius: 50%;
					background: #eef9ff;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					img {
						width: 72px;
					}
				}
				h2 {
					font-weight: 600;
					font-size: 22px;
					margin-bottom: 16px;
				}
				p {
					font-weight: 500;
					font-size: 14px;
					margin: 0;
					text-align: center;
					max-width: 280px;
				}
			}
		}
		.card {
			height: 150px;
		}
	}

	&.desktop {
		.page-content {
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch !important;
		}
		.content-ctn {
			max-width: 630px;
			margin-left: auto;
			margin-right: auto;
			.spacer {
				height: 250px;
			}
		}
	}
}
