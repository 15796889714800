@import '../../css/variables';
.product-setup-sheet-cmp {
	border-radius: 12px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	height: auto;
	.setup-content {
		padding: $p4;
		max-height: 100vh;
		.loading {
			padding: $p4;
			margin-bottom: $m4;
		}
		.setup-header {
			margin-bottom: $m5;
			a {
				color: $primary;
				font-weight: bold;
				font-size: $f2;
			}
			.title {
				font-size: $f6;
				font-weight: bold;
				color: $primary;
				width: calc(100% - 88px);
				text-align: center;
				font-family: $pop;
			}
		}
		.success-text {
			color: $primary;
			font-size: $f2;
			text-align: center;
			margin-bottom: $m8;
		}
		.product-ctn {
			margin-bottom: $m10;
			.name {
				color: $primary;
				font-size: $f4;
				margin-bottom: $m2;
				font-weight: 600;
			}
			img {
				height: 75px;
			}
		}

		.setup-step-ctn {
			.setup-header {
				.title {
					font-size: $f5;
					width: 100%;
					text-align: center;
				}
			}
			.sub-text {
				color: $primary;
				font-size: $f2;
				text-align: center;
				margin-bottom: $m4;
				&.left-align {
					text-align: left;
				}
			}
			.product-ctn {
				margin-bottom: $m8;
			}
			.step-svg {
				display: flex;
				justify-content: center;
				margin-bottom: $m8;
				svg {
					width: 50%;
					max-height: 150px;
				}
				.bx-calendar-heart {
					font-size: $f8;
					color: $red-500;
				}
			}
			.skin-tone-selector {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				margin-bottom: $m8;
				img {
					width: 75px;
					padding: $p4;
					cursor: pointer;
					transition: 0.1s;
					&.selected {
						border: 4px solid $bgGradiantLight;
						border-radius: 12px;
						padding: 12px;
						opacity: 1;
					}
				}
			}
			.pin-code {
				input[type='tel'] {
					width: 100%;
				}
			}
			.address-text {
				justify-content: center;
				margin-bottom: $m8;
				color: $primary;
				width: 100%;
				i {
					margin-right: $m4;
					font-size: $f8;
				}
				.key-value {
					font-size: $f2;
					.key {
						font-size: $f1;
					}
				}
			}
			.sync-icon {
				margin-bottom: $m4;
				i {
					font-size: 55px;
					color: $green-500;
				}
			}
			.btn-ctn {
				.primary-btn {
					color: $white;
					margin-bottom: $m4;
					font-size: $f4;
					text-transform: uppercase;
					width: 100%;
					height: 44px;
				}
				.secondary-btn {
					font-size: $f4;
					text-transform: capitalize;
					width: 100%;
					height: 44px;
					border-color: $greyText;
					color: $greyText;
				}
				.btn-btn {
					margin-top: $m8;
					margin-bottom: $m8;
					.sub-text {
						margin-bottom: $m1;
					}
				}
			}
		}
	}
	&.mobile {
		.sheet-modal-inner {
			overflow-y: scroll;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch !important;
		}
	}
	&.desktop {
		max-width: 630px;
		margin-left: calc((100vw - 630px) / 2);
		margin-top: $m10;
		border-radius: 12px;
		&.modal-out {
			margin-top: 0;
		}
		.setup-step-ctn {
			.skin-tone-selector {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				margin-bottom: $m8;
				img {
					width: 65px;
					padding: $p4;
					cursor: pointer;
					transition: 0.1s;
					&:hover {
						transform: scale(1.1);
					}
					&.selected {
						border: 4px solid $bgGradiantLight;
						border-radius: 12px;
						padding: 12px;
						opacity: 1;
					}
				}
			}
		}
	}
}
