@import '../../css/variables';

.credit-card-input {
	width: 100%;
	position: relative;
	height: 40px;
	margin-bottom: $m8;
	.label {
		font-family: $pop;
		color: $primary;
		font-size: 14px;
		margin-bottom: 3px;
		margin-left: 16px;
	}
	.gradiant {
		background: linear-gradient(90deg, $buttonLight 0%, $buttonDark 100%);
		border-radius: 500px;
		height: 44px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.StripeElement {
		background: #fff;
		width: calc(100% - 4px);
		font-size: 18px;
		height: 40px;
		background: #ffffff;
		border-radius: 20px;
		padding: 12px 24px;
		padding-left: 12px;
		color: $text;
		transition: 0.3s;
		color: $greyText;
		&::placeholder {
			color: $greyText;
		}
		&:focus {
			box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 3px $primary;
		}
	}
}
