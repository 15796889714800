@import '../../css/variables.scss';
.goal-widgit {
	font-family: $pop;
	background: $white;
	border-radius: 12px;
	.color-blue {
		a {
			background: #bce9fe;
			color: $primary;
		}
	}
	h1 {
		margin-top: 0;
	}
	h2 {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #0061ab;
		margin-bottom: 16px;
	}
	.divider-line {
		height: 2px;
		width: 100%;
		max-width: 320px;
		background: #e5e5e5;
		margin: 0 auto;
		margin-bottom: $m6;
	}
	.goal-list-cta {
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid #e2e8f0;
		padding-top: 16px;
		cursor: pointer;
		.list-cta {
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			display: flex;
			align-items: center;
			text-align: center;
			color: #1b99fb;
		}
	}
	.empty-message {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: $primary;
		margin-bottom: 100px;
		box-shadow: $cardShadow;
		border-radius: 12px;
		padding: 32px;
		.add-goal-btn {
			background: $white;
		}
		.image-ctn {
			width: 120px;
			height: 120px;
			border-radius: 50%;
			background: #eef9ff;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			img {
				width: 72px;
			}
		}
		h2 {
			font-weight: 600;
			font-size: 22px;
			margin-bottom: 16px;
		}
		p {
			font-weight: 500;
			font-size: 14px;
			margin: 0;
			text-align: center;
			max-width: 280px;
			margin-bottom: $m4;
		}
	}
	.goals-list {
		padding: $p4 $p4 $p4 $p4;
		box-shadow: $cardShadow;
		border-radius: 12px;
		.goal-card-ctn {
			border-top: 1px solid #e2e8f0;
			.status {
				.checkbox-checked,
				.empty-checkbox {
					width: 24px;
					height: 24px;
					margin: 10px;
					margin-left: 0;
					border-radius: 50%;
					border: 2px solid $text2;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 22px;
					transition: 0.2s;
					cursor: pointer;
				}
				.checkbox-checked {
					color: $white;
					background: radial-gradient(128.13% 792.96% at 99.71% 144.04%, #0061ab 0%, #169aff 41.15%, #38bcf6 84.37%);
					i {
						margin-bottom: 2px;
						margin-right: 2px;
					}
				}
			}
		}
		.goal-card {
			padding: $p4;
			margin-bottom: $m2;
			margin-top: $m2;
			background: $white;
			display: flex;
			align-items: center;
			cursor: pointer;
			transition: 0.3s;
			width: 100%;
			border-left: 1px solid #e2e8f0;
			.date {
				margin-right: $m2;
				padding-right: $p2;
				.day {
					font-weight: 500;
					color: $text2;
					font-size: $f1;
					margin-bottom: -4px;
				}
				.daynum {
					font-weight: 600;
					font-size: $f6;
					color: $primary;
					margin-bottom: -5px;
				}
				.month {
					font-weight: 500;
					color: $primary;
					font-size: $f1;
				}
			}

			.goal-body {
				.text-body {
					color: $primary;
					font-weight: 500;
					font-size: 12px;
					max-width: 230px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
				.goal-type {
					margin-bottom: $m2;
					color: $white;
					font-weight: 600;
					font-size: $f1;
					text-transform: uppercase;
					padding: 2px;
					text-align: center;
					border-radius: 500px;
					max-width: 100px !important;
					&.onetime {
						background: $primary;
						width: 100px;
					}
					&.repeatable {
						background: $text2;
					}
				}
				.due-message {
					font-size: 12px;
					margin-top: 8px;
					color: $greyText;
					&.red {
						color: $red-500;
					}
				}
			}

			&:hover {
				box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
				border-radius: 12px;
			}
		}
	}
	.spacer {
		height: 50px;
	}

	&.desktop {
		.page-content {
			overflow-y: scroll;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch !important;
		}
		.content-ctn {
			max-width: 630px;
			margin-left: auto;
			margin-right: auto;
			.header-ctn {
				margin-bottom: 320px;
			}
			.spacer {
				height: 200px;
			}
			.goal-card {
				.goal-body {
					width: 100%;
					.text-body {
						width: 100%;
						margin-right: 20px;
					}
				}
			}
		}
	}
}
