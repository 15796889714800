@import '../../css/variables';
.notification-popup {
	background: $white;
	z-index: 999999;
	.popup-header {
		width: 100%;
		padding: $p4;
		height: 87px;
		.back-btn {
			color: $text;
			font-weight: bold;
			font-size: $f4;
			background-color: #bce9fe;
			height: 32px;
			width: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			margin: 12px;
			i {
				font-size: 32px;
			}
		}
	}
	.page-ctn {
		padding: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		h1 {
			margin-bottom: 64px;
			font-weight: 600;
			font-size: 32px;
			margin-top: 0;
		}
		.image-ctn {
			width: 120px;
			height: 120px;
			border-radius: 50%;
			background: #eef9ff;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			margin-bottom: 16px;
			img {
				width: 72px;
			}
		}
		h3 {
			color: $primary;
			font-weight: 600;
			font-size: 22px;
			margin-bottom: 0;
		}
		p {
			margin-bottom: 16px;
			color: $primary;
			font-weight: 500;
			font-size: 14px;
		}
		.primary-btn {
			color: $white;
			margin-bottom: 32px;
			padding: 0 32px;
			margin-top: 16px;
		}
		.secondary-btn {
			width: 150px;
			max-width: 150px;
			max-height: 44px;
			font-size: 14px;
			border: 2px solid $text2;
		}
	}
}
