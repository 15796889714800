@import '../../css/variables.scss';
.bvr-goals-page-cmp {
	font-family: $pop;
	background: $white;
	.color-blue {
		a {
			background: #bce9fe;
			color: $primary;
		}
	}
	.background {
		background: linear-gradient(180deg, rgba(26, 156, 255, 0) 75%, $bgGradiantLight 90%, $bgGradiantDark 100%);
		position: fixed;
		z-index: -1;
		min-height: 900px;
		width: 100%;
		height: 100%;
	}

	.content-ctn {
		animation-duration: 0.3s;
		height: 100%;
		max-height: calc(100vh - 75px);
		.bg-image {
			max-width: 630px;
			width: 100%;
			z-index: -1;
		}
		h1 {
			letter-spacing: -1px;
			text-align: center;
			margin: 0;
			font-weight: 600;
			font-size: $f7;
			margin-bottom: $m4;
			transition: 0.2s;
		}
		.dropdown-ctn {
			margin-bottom: $m8;
			text-align: center;
			border-bottom: 1px solid $gray-200;
		}
		.divider-line {
			height: 2px;
			width: 100%;
			max-width: 320px;
			background: #e5e5e5;
			margin: 0 auto;
			margin-bottom: $m6;
		}
		.filter-list {
			padding: 0 $p4;
			padding-bottom: $p4;
			margin-left: -16px;
			margin-right: -16px;
			margin-bottom: $m4;
			.filter {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 32px;
				color: $text2;
				text-align: center;
				//padding: $p2 $p7;
				font-weight: 600;
				border: 2px solid $text2;
				border-radius: $primaryRadius;
				margin-right: $m2;
				transition: 0.3s;
				cursor: pointer;
				&:hover,
				&.active {
					background: $text2;
					color: $white;
				}
				&:first-child {
					margin-left: 16px;
				}
			}
		}
		.empty-message {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			color: $primary;
			margin-bottom: 100px;
			.add-goal-btn {
				background: $white;
			}
			.image-ctn {
				width: 120px;
				height: 120px;
				border-radius: 50%;
				background: #eef9ff;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				img {
					width: 72px;
				}
			}
			h2 {
				font-weight: 600;
				font-size: 22px;
				margin-bottom: 16px;
			}
			p {
				font-weight: 500;
				font-size: 14px;
				margin: 0;
				text-align: center;
				max-width: 280px;
				margin-bottom: $m4;
			}
		}
		.goals-list {
			padding: 0 $p4 $p4 $p4;
			.goal-card-ctn {
				.status {
					.checkbox-checked,
					.empty-checkbox {
						width: 24px;
						height: 24px;
						margin: 10px;
						margin-left: 0;
						border-radius: 50%;
						border: 2px solid $text2;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 22px;
						transition: 0.2s;
						cursor: pointer;
					}
					.checkbox-checked {
						color: $white;
						background: radial-gradient(128.13% 792.96% at 99.71% 144.04%, #0061ab 0%, #169aff 41.15%, #38bcf6 84.37%);
						i {
							margin-bottom: 2px;
							margin-right: 2px;
						}
					}
				}
			}
			.goal-card {
				padding: $p4;
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
				border-radius: $primaryRadius;
				margin-bottom: $m4;
				background: $white;
				display: flex;
				align-items: center;
				cursor: pointer;
				transition: 0.3s;
				width: 100%;

				.date {
					margin-right: $m2;
					padding-right: $p2;
					border-right: 1px solid $gray-200;
					.day {
						font-weight: 500;
						color: $text2;
						font-size: $f1;
						margin-bottom: -4px;
					}
					.daynum {
						font-weight: 600;
						font-size: $f6;
						color: $primary;
						margin-bottom: -5px;
					}
					.month {
						font-weight: 500;
						color: $primary;
						font-size: $f1;
					}
				}

				.goal-body {
					.text-body {
						color: $primary;
						font-weight: 500;
						font-size: 12px;
						max-width: 230px;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
					.goal-type {
						margin-bottom: $m2;
						color: $white;
						font-weight: 600;
						font-size: $f1;
						text-transform: uppercase;
						padding: 2px;
						text-align: center;
						border-radius: 500px;
						max-width: 100px !important;
						&.onetime {
							background: $primary;
							width: 100px;
						}
						&.repeatable {
							background: $text2;
						}
					}
					.due-message {
						font-size: 12px;
						margin-top: 8px;
						color: $greyText;
						&.red {
							color: $red-500;
						}
					}
				}

				&:hover {
					box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
				}
			}
		}
		.spacer {
			height: 50px;
		}
	}

	&.desktop {
		.page-content {
			overflow-y: scroll;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch !important;
		}
		.content-ctn {
			max-width: 630px;
			margin-left: auto;
			margin-right: auto;
			.header-ctn {
				margin-bottom: 320px;
			}
			.spacer {
				height: 200px;
			}
			.goal-card {
				.goal-body {
					width: 100%;
					.text-body {
						width: 100%;
						margin-right: 20px;
					}
				}
			}
		}
	}
}

.toast {
	background: #bce9fe !important;
	border-radius: 12px !important;
	color: $primary;
	font-weight: 500;
	font-style: italic;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
}
