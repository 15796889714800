@import '../../css/variables.scss';
.thought-capture-popup {
	font-family: $pop;
	background: $white;
	.information-container {
		padding: $p4;
		overflow-y: scroll;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch !important;
		height: 100%;
		.info-header {
			width: 100%;
			.close-btn {
				font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
				font-size: $f6;
				background: $lightBlue-200;
				width: 32px;
				height: 32px;
				text-align: center;
				border-radius: 50%;
				color: $primary;
				cursor: pointer;
			}
		}
		.image-icon {
			width: 120px;
			margin-bottom: $m8;
		}
		.category-label {
			font-weight: 600;
			font-size: 22px;
			margin-bottom: $m8;
			color: $primary;
		}
		.info-box {
			padding: $p6 $p4;
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
			border-radius: $primaryRadius;
			color: $primary;
			font-weight: 500;
			white-space: pre-line;
			background: $white;
		}
	}
	.background {
		background: linear-gradient(180deg, rgba(26, 156, 255, 0) 85%, $bgGradiantLight 95%, $bgGradiantDark 100%);
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.page-ctn {
		padding: $p4;
		width: 100%;
		height: 100%;
		text-align: center;
		z-index: 1;
		.title {
			padding-top: $p5;
			font-weight: 600;
			font-size: $f5;
			text-align: center;
			color: $primary;
			margin-bottom: $m7;
		}

		.card-btn {
			margin-bottom: $m7;
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
			width: 100%;
			padding: $p4;
			border-radius: $primaryRadius;
			color: $text2;
			font-weight: 600;
			font-size: $f3;
			height: 80px;
			transition: 0.3s;
			background: $white;
			cursor: pointer;
			.icon-ctn {
				width: 60px;
				height: 60px;
				border-radius: 50%;
				//background: $primary;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: $m4;
				img {
					width: 40px;
				}
			}
			.category-label {
				text-align: left;
			}
			.info-ctn {
				width: 44px;
				height: 44px;
				display: flex;
				justify-content: center;
				align-items: center;
				.info-icon {
					width: 32px;
					height: 32px;
					border-radius: 50%;
					background: #eefaff;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
				}
			}
			&.selected-cat {
				background: $text2;
				cursor: pointer;
				color: $white;
				box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
				.icon-ctn {
					background: #e0f1ff;
				}
				.info-ctn {
					.info-icon {
						color: $primary;
					}
				}
			}
		}
		.step-btn {
			border-radius: 20px;
			border: 2px solid $text2;
			color: $text2;
			height: 44px;
			width: 100px;
			padding: $p2 $p7;
			font-weight: 600;
			font-size: $f2;
			background: $white;
		}
		.date-selector {
			color: $primary;
			margin-bottom: $m2;
			input {
				color: $primary;
				text-decoration: underline;
				font-weight: 600;
				width: 120px;
				padding: 16px;
				padding-left: 44px;
				margin-left: -44px;
				&::placeholder {
					color: $primary;
					text-decoration: underline;
					font-weight: 600;
				}
			}
			.cal-icon {
				background-color: #eefaff;
				height: 38px;
				width: 38px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $primary;
				margin-right: $m2;
				i {
					font-size: $f5;
				}
			}
		}
		.input-counter {
			width: 100%;
			height: 16px;
			margin-top: -16px;
			color: $primary;
			font-weight: 500;
			font-size: $f1;
			&.red {
				color: $red-500;
			}
		}
		.thought-trigger {
			textarea {
				height: 100px;
			}
		}
		.thought-input {
			textarea {
				height: 250px;
			}
		}
		.lantern {
			width: 120px;
			margin-bottom: $m8;
		}
		.impact-text {
			font-weight: 600;
			font-size: $f3;
			color: $text2;
			margin-bottom: $m8;
		}
		.rc-slider {
			margin-bottom: $m8;
			width: 300px;
		}

		.spacer {
			height: 32px;
		}
		.btn-ctn {
			width: 100%;
		}

		.success-screen {
			color: $white;
			text-align: center;
			padding: $p4;
			padding-top: 44px;
			z-index: 1;
			h1 {
				margin: 0;
				font-weight: 600;
				font-size: $f7;
				margin-bottom: $m9;
			}
			.date {
				margin-bottom: $m4;
				text-transform: capitalize;
				font-weight: 600;
			}
			.thought-text {
				margin-bottom: $m8;
				background: #dfecf8;
				padding: $p4;
				border-radius: $primaryRadius;
				color: $primary;
				text-align: left;
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
				img {
					width: 14px;
					margin-right: $m2;
					margin-top: -2px;
				}
				.toggle-text {
					font-weight: 600;
					color: $primary;
				}
				.thought {
					margin-bottom: $m2;
					word-break: break-all;
				}
			}
			h3 {
				margin-bottom: $m4;
				font-weight: 600;
				font-size: $f5;
			}
			.glass-btn {
				margin-bottom: $m6;
				background: #75a9da;
				border-radius: 500px;
				height: 44px;
				border: 2px solid $white;
				color: $white;
				font-weight: 600;
				font-size: $f2;
				width: 280px;
				text-transform: none;
			}
		}
	}
	.rc-slider {
		position: relative;
		height: 14px;
		padding: 5px 0;
		width: 100%;
		border-radius: 6px;
		touch-action: none;
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
	.rc-slider * {
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
	.rc-slider-rail {
		position: absolute;
		width: 100%;
		background-color: #e9e9e9;
		height: 4px;
		border-radius: 6px;
	}
	.rc-slider-track {
		position: absolute;
		left: 0;
		height: 4px;
		border-radius: 6px;
		background-color: #1b99fb;
	}
	.rc-slider-handle {
		position: absolute;
		width: 32px;
		height: 32px;
		cursor: pointer;
		cursor: -webkit-grab;
		margin-top: -14px;
		cursor: grab;
		border-radius: 50%;
		border: solid 2px #1b99fb;
		background-color: #1b99fb;
		touch-action: pan-x;
		box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);
	}
	.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
		border-color: #1b99fb;
		box-shadow: 0 0 0 5px #1b99fb;
	}
	.rc-slider-handle:focus {
		outline: none;
	}
	.rc-slider-handle-click-focused:focus {
		border-color: #1b99fb;
		box-shadow: unset;
	}
	.rc-slider-handle:hover {
		border-color: #1b99fb;
	}
	.rc-slider-handle:active {
		border-color: #1b99fb;
		box-shadow: 0 0 5px #1b99fb;
		cursor: -webkit-grabbing;
		cursor: grabbing;
	}
	.rc-slider-mark {
		position: absolute;
		top: 18px;
		left: 0;
		width: 100%;
		font-size: 12px;
	}
	.rc-slider-mark-text {
		position: absolute;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		cursor: pointer;
		color: #999;
	}
	.rc-slider-mark-text-active {
		color: #666;
	}
	.rc-slider-step {
		position: absolute;
		width: 100%;
		height: 4px;
		background: transparent;
	}
	.rc-slider-dot {
		position: absolute;
		bottom: -2px;
		margin-left: -4px;
		width: 8px;
		height: 8px;
		border: 2px solid #e9e9e9;
		background-color: #fff;
		cursor: pointer;
		border-radius: 50%;
		vertical-align: middle;
	}
	.rc-slider-dot-active {
		border-color: #1b99fb;
	}
	.rc-slider-dot-reverse {
		margin-right: -4px;
	}
	.rc-slider-disabled {
		background-color: #e9e9e9;
	}
	.rc-slider-disabled .rc-slider-track {
		background-color: #ccc;
	}
	.rc-slider-disabled .rc-slider-handle,
	.rc-slider-disabled .rc-slider-dot {
		border-color: #ccc;
		box-shadow: none;
		background-color: #fff;
		cursor: not-allowed;
	}
	.rc-slider-disabled .rc-slider-mark-text,
	.rc-slider-disabled .rc-slider-dot {
		cursor: not-allowed !important;
	}
	.rc-slider-vertical {
		width: 14px;
		height: 100%;
		padding: 0 5px;
	}
	.rc-slider-vertical .rc-slider-rail {
		height: 100%;
		width: 4px;
	}
	.rc-slider-vertical .rc-slider-track {
		left: 5px;
		bottom: 0;
		width: 4px;
	}
	.rc-slider-vertical .rc-slider-handle {
		margin-left: -5px;
		touch-action: pan-y;
	}
	.rc-slider-vertical .rc-slider-mark {
		top: 0;
		left: 18px;
		height: 100%;
	}
	.rc-slider-vertical .rc-slider-step {
		height: 100%;
		width: 4px;
	}
	.rc-slider-vertical .rc-slider-dot {
		left: 2px;
		margin-bottom: -4px;
	}
	.rc-slider-vertical .rc-slider-dot:first-child {
		margin-bottom: -4px;
	}
	.rc-slider-vertical .rc-slider-dot:last-child {
		margin-bottom: -4px;
	}
	.rc-slider-tooltip-zoom-down-enter,
	.rc-slider-tooltip-zoom-down-appear {
		animation-duration: 0.3s;
		animation-fill-mode: both;
		display: block !important;
		animation-play-state: paused;
	}
	.rc-slider-tooltip-zoom-down-leave {
		animation-duration: 0.3s;
		animation-fill-mode: both;
		display: block !important;
		animation-play-state: paused;
	}
	.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
	.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
		animation-name: rcSliderTooltipZoomDownIn;
		animation-play-state: running;
	}
	.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
		animation-name: rcSliderTooltipZoomDownOut;
		animation-play-state: running;
	}
	.rc-slider-tooltip-zoom-down-enter,
	.rc-slider-tooltip-zoom-down-appear {
		transform: scale(0, 0);
		animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	}
	.rc-slider-tooltip-zoom-down-leave {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	}
	@keyframes rcSliderTooltipZoomDownIn {
		0% {
			opacity: 0;
			transform-origin: 50% 100%;
			transform: scale(0, 0);
		}
		100% {
			transform-origin: 50% 100%;
			transform: scale(1, 1);
		}
	}
	@keyframes rcSliderTooltipZoomDownOut {
		0% {
			transform-origin: 50% 100%;
			transform: scale(1, 1);
		}
		100% {
			opacity: 0;
			transform-origin: 50% 100%;
			transform: scale(0, 0);
		}
	}
	.rc-slider-tooltip {
		position: absolute;
		left: -9999px;
		top: -9999px;
		visibility: visible;
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
	.rc-slider-tooltip * {
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
	.rc-slider-tooltip-hidden {
		display: none;
	}
	.rc-slider-tooltip-placement-top {
		padding: 4px 0 8px 0;
	}
	.rc-slider-tooltip-inner {
		padding: 6px 2px;
		min-width: 24px;
		height: 24px;
		font-size: 12px;
		line-height: 1;
		color: #fff;
		text-align: center;
		text-decoration: none;
		background-color: #6c6c6c;
		border-radius: 6px;
		box-shadow: 0 0 4px #d9d9d9;
	}
	.rc-slider-tooltip-arrow {
		position: absolute;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}
	.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
		bottom: 4px;
		left: 50%;
		margin-left: -4px;
		border-width: 4px 4px 0;
		border-top-color: #6c6c6c;
	}
}
.calendar {
	border-radius: $primaryRadius;
	.calendar-months {
		background: $primary;
		color: $white;
		.calendar-day-number {
			color: $white;
		}
		.calendar-day-today {
			.calendar-day-number {
				background-color: $gray-800;
			}
		}
		.calendar-day-selected {
			.calendar-day-number {
				background-color: $buttonLight;
				font-weight: 600;
			}
		}
		.calendar-day-disabled {
			background: $gray-800;
		}
	}
	.calendar-week-header {
		background: $gray-800;
		color: $white;
	}
	.toolbar,
	.calendar-header,
	.calendar-footer {
		background: $text2;
		color: $white;
		a {
			color: $white;
			font-weight: 600;
		}
	}
}
