@import '../../css/variables.scss';
.goal-details-popup {
	font-family: $pop;
	background: $white;
	.content-ctn {
		padding: $p4;
		animation-duration: 0.3s;
		height: 100%;

		.header-ctn {
			padding: $p4 0;
			.title {
				font-size: $f5;
				font-weight: 600;
				color: $white;
				text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.55);
				img {
					width: 44px;
					margin-right: $m4;
				}
			}
			.settings-btn {
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #eefaff;
				border: 2px solid #fff;
				border-radius: 50%;
				width: 44px;
				height: 44px;
				i {
					font-size: $f7;
					color: $primary;
				}
			}
		}
		.tag-ctn {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: $m2;
			.goal-type {
				margin-bottom: $m2;
				color: $white;
				font-weight: 600;
				font-size: $f1;
				text-transform: uppercase;
				padding: 2px 16px;
				text-align: center;
				border-radius: 500px;
				max-width: 100px !important;
				&.onetime {
					background: $primary;
					width: 100px;
				}
				&.repeatable {
					width: 100px;
					background: $text2;
				}
				&.irl {
					width: 100px;
					color: $text2;
					border: 1px solid $text2;
					margin-left: 16px;
				}
			}
		}

		h1 {
			letter-spacing: -1px;
			text-align: center;
			margin: 0;
			font-weight: 600;
			font-size: $f5;
			margin-bottom: $m4;
			word-wrap: break-word;
		}
		h3 {
			font-weight: 600;
			font-size: 16px;
			color: $primary;
		}

		.goal-card {
			padding: $p4;
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
			border-radius: $primaryRadius;
			margin-bottom: $m8;
			background: $white;
			cursor: pointer;
			min-height: 60px;
			transition: 0.4s;
			.label {
				font-weight: 600;
				font-size: 14px;
				color: $text2;
				margin-bottom: 4px;
			}
			.value {
				font-weight: 500;
				font-size: 14px;
				color: $primary;
				strong {
					font-weight: 600;
				}
				div {
					margin-top: 8px;
				}
				&.ltg {
					img {
						margin-right: 16px;
						height: 60px;
					}
				}
			}
			.count {
				width: 50px;
				height: 50px;
				background: $text2;
				color: $white;
				font-weight: 500;
				font-size: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				margin-right: 16px;
			}
			.messagebox {
				.text1 {
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					color: $primary;
				}
				.text2 {
					color: $greyText;
					font-weight: 500;
					font-size: 14px;
				}
			}

			.divider {
				border-bottom: 1px solid #e2e8f0;
				margin: 16px 0;
			}
		}
		.due-message {
			font-weight: 600;
			font-size: 14px;
			margin-top: 8px;
			margin-bottom: 16px;
			color: $greyText;
			text-align: center;
			width: 100%;
			&.red {
				color: $red-500;
			}
			&.completed {
				color: $green-500;
			}
		}
		.step-btn {
			border-radius: 20px;
			border: 2px solid $text2;
			color: $text2;
			height: 44px;
			width: 300px;
			margin-bottom: 16px;
			padding: $p2 $p7;
			font-weight: 600;
			font-size: $f2;
			background: $white;
			text-transform: none;
		}
		.spacer {
			height: 50px;
		}
	}

	&.desktop {
		.page-content {
			overflow-y: scroll;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch !important;
		}
		.content-ctn {
			max-width: 630px;
			margin-left: auto;
			margin-right: auto;
			.goal-card {
				.goal-body {
					width: 100%;
					.text-body {
						width: 100%;
						margin-right: 20px;
					}
				}
			}
		}
	}
}

.dialog-buttons-vertical {
	.dialog-buttons {
		.dialog-button {
			font-size: 13px;
			text-align: center;
			width: 100%;
			font-weight: bold;
		}
	}
}

.popup-behind {
	z-index: 10499;
}
