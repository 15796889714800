@import '../../css/variables';
.account-mgmt-page-popup {
	background: linear-gradient(180deg, $bgGradiantLight 56.91%, $bgGradiantDark 100%);
	.popup-header {
		width: 100%;
		padding: $p4;
		height: 87px;
		.back-btn {
			color: $white;
			font-weight: 500;
			font-size: $f4;
			i {
				margin-right: $m1;
				font-size: $f6;
			}
		}
		img {
			width: 55px;
		}
	}
	.main-content {
		padding: $p4;
		padding-top: 0;
		height: calc(100% - 100px);
		h1 {
			color: $white;
			margin-top: 0;
			font-weight: 600;
			font-size: $f7;
		}
		.account-card {
			width: 100%;
			padding: $p6;
			height: 100px;
			margin-bottom: $m8;
			background: rgba(255, 255, 255, 0.25);
			border: 1px solid #ffffff;
			backdrop-filter: blur(50px);
			-webkit-backdrop-filter: blur(50px);
			border-radius: 12px;
			color: $white;
			font-size: $f4;
			font-weight: 500;
			text-shadow: 0px 0px 5px rgba(0, 97, 171, 0.82);
			cursor: pointer;
			.bx-edit,
			.bx-shopping-bag,
			.bx-lock,
			.bx-sync,
			.bx-message,
			.bx-exit,
			.bx-mobile-alt,
			.bx-help-circle {
				font-size: $f6;
				margin-right: $m2;
			}

			.bx-right-arrow-alt {
				margin-left: $m2;
				font-size: $f7;
			}
			transition: 0.3s;
			&:hover {
				background: rgba(255, 255, 255, 0.45);
			}
		}
	}
	&.desktop {
	}
}

.manage-profile-popup {
	background: $white;
	.popup-header {
		width: 100%;
		padding: $p4;
		height: 87px;
		.back-btn {
			color: $primary;
			font-weight: 500;
			font-size: $f4;
			i {
				margin-right: $m1;
				font-size: $f6;
			}
		}
		img {
			width: 200px;
			right: -75px;
			position: absolute;
		}
	}
	.main-content {
		padding: $p4;
		padding-top: 0;
		height: calc(100% - 87px);
		h1 {
			color: $primary;
			margin-top: 0;
			font-weight: 600;
			font-size: $f7;
		}
		.skintone-ctn {
			padding: $p4;
			padding-top: 0;
			h3 {
				color: $text;
				margin: 0;
				margin-bottom: $m1;
				text-align: left;
				width: 100%;
				font-weight: 600;
			}
			.sub-text {
				color: $text;
				margin-bottom: $m4;
			}
			.skin-tone-selector {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				padding: $p4;
				padding-top: 0;
				img {
					width: 60px;
					padding: $p3;
					cursor: pointer;
					transition: 0.1s;
					&.selected {
						border: 4px solid $bgGradiantLight;
						border-radius: 12px;
						padding: 12px;
						opacity: 1;
					}
				}
			}
		}
	}
	&.desktop {
	}
}
