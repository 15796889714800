@import '../../css/variables.scss';
.signup-page {
	font-family: $pop;
	background: $white;
	.page-content {
		overflow: hidden;
	}
	.background {
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 55%, $bgGradiantLight 80%, $bgGradiantDark 94%);
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		transition: 0.3s;
		&.step-0 {
			margin-top: 300px;
		}
		&.step-3 {
			margin-top: 100px;
			background: linear-gradient(180deg, #ffffff 80%, $bgGradiantLight 90%);
		}
		&.step-4 {
			margin-top: 100px;
			background: linear-gradient(180deg, #ffffff 65%, $bgGradiantLight 90%);
		}
	}
	.signup-content {
		z-index: 1;
		height: 100%;
		&.iosfix {
			backdrop-filter: none;
		}
		&.step-3 {
			overflow-y: scroll;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch !important;
			padding-bottom: 45px;
		}
		.signup-header {
			padding-top: $p10;
			margin-bottom: $m10;
			&.d2c {
				margin-bottom: $m4;
				padding-top: $p8;
			}
			.icon-logo {
				margin-bottom: $m4;
				width: 180px;
			}
			.text-logo {
				margin-bottom: $m2;
				width: 130px;
			}
			.subtitle {
				font-size: $f4;
				color: $greyText;
			}
			.nurture-logo {
				max-width: 56px;
			}
			.nurture-text {
				font-family: $pop;
				font-weight: 700;
				font-size: 36px;
				margin-bottom: -8px;
				background: -webkit-linear-gradient(91.89deg, #bb64d6 13.42%, #9700c6 117.5%);
				background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			.created-by {
				color: $text;
				font-size: 12px;
				text-align: left;
				width: 170px;
				font-family: $pop;
				margin-bottom: 4px;
			}
			.icon-logo-d2c {
				width: 120px;
			}
		}
		.form {
			max-width: 348px;
			padding: 0 $p6;
			margin: 0 auto;
			h1 {
				font-family: $pop;
				color: $text;
				font-weight: 700;
				font-size: $f7;
				margin-top: 0;
			}
			.d2c-message {
				font-family: $pop;
				color: $text;
				margin-bottom: $m4;
				font-size: 12px;
				margin-top: -28px;
				margin-left: 4px;
			}
			.button {
				margin-bottom: $m4;
			}
			.subtext {
				font-weight: bold;
				font-size: $f1;
				text-align: center;
				color: $text;
				font-family: $pop;
				text-shadow: 0 0 20px #fff;
			}
			.subheader {
				margin-bottom: $m4;
				img {
					height: 80px;
				}
				h1 {
					&.thin {
						font-weight: 400;
						color: $greyText;
						margin: 0;
						font-size: $f5;
					}
					&.phone {
						margin: 0;
						font-size: $f5;
					}
				}
			}
			.password-message {
				font-size: $f1;
				color: $text;
				width: 325px;
				margin: 0 auto;
				margin-bottom: $m4;
				font-weight: 600;
			}
			.address-input {
				margin-bottom: $m6;
				i {
					color: $text;
					font-size: $f5;
				}
			}
			.loader-ctn {
				margin-bottom: $m6;
			}
			.select-text {
				font-size: $f1;
				color: $text;
				width: 325px;
				margin: 0 auto;
				margin-bottom: $m4;
				margin-top: -$m4;
				font-weight: 600;
			}
			.address-card {
				cursor: pointer;
				padding: $p4;
				background: $white;
				margin-bottom: $m4;
				font-size: $f3;
				height: 60px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 12px;
				box-shadow: $cardShadow;
				transition: 0.3s;
				&:hover {
					background: $text;
					color: $white;
				}
			}
			.terms-toggle {
				cursor: pointer;
				margin-bottom: $m6;
				.toggle-wrap {
					min-width: 36px;
					min-height: 36px;
					margin-right: $m4;
					background: linear-gradient(90deg, $buttonLight 0%, $buttonDark 100%);
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					transition: 0.3s;
					.toggle {
						width: 30px;
						height: 30px;
						background: $white;
						border-radius: 50%;
						box-shadow: inset 3px 3px 4px $primary;
						display: flex;
						justify-content: center;
						align-items: center;
						div {
							background: $buttonDark;
							min-width: 20px;
							min-height: 20px;
							border-radius: 50%;
							margin-left: 2px;
							margin-top: 2px;
							display: flex;
							justify-content: center;
							align-items: center;
							span {
								background: $buttonDark;
								min-width: 10px;
								min-height: 10px;
								border-radius: 50%;
							}
						}
					}
				}
				.terms {
					color: #000;
					font-size: $f2;
					a {
						color: $text;
						margin-left: $m1;
						text-decoration: underline;
						font-weight: bold;
						font-size: $f1;
					}
				}
			}
			.privacy-message {
				margin-bottom: 50px;
				a {
					color: $text;
					margin-left: $m1;
					text-decoration: underline;
					font-weight: bold;
				}
			}
			.health-cta {
				position: absolute;
				bottom: 56px;
				left: 0px;
				width: 100%;
				padding: 16px 38px;
				display: flex;
				justify-content: center;
				align-items: center;
				.cta-text {
					color: $white;
					text-align: center;
					font-weight: 500;
					font-size: 12px;
					max-width: 400px;
				}
			}
			.partner-cta {
				border-radius: 12px;
				cursor: pointer;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 16px;
				width: 160px;
				height: 106px;
				margin: 72px auto 0 auto;
				animation-duration: 0.6s;
				.cta-text {
					margin-bottom: $m2;
					color: $white;
					font-size: $f1;
				}
				img {
					width: 100px;
					border-radius: 12px;
				}
			}
		}
		.completed-section {
			position: relative;
			top: 0;
			max-width: 400px;
			padding: 0 $p6;
			margin: 0 auto;
			height: calc(100% - 0px) !important;
			color: $greyText;
			h1 {
				color: $primary;
			}
			h3 {
				font-size: $f4;
				font-weight: 400;
				color: $greyText;
			}
			.success-message {
				margin-bottom: $m8;
			}
			img {
				width: 225px;
				cursor: pointer;
				transition: 0.3s;
				margin-bottom: $m6;
				&:hover {
					transform: scale(1.125);
				}
			}

			.primary-btn {
				margin-bottom: $m4;
			}
			.text-btn {
				font-weight: bold;
				color: $primary;
				text-decoration: underline;
				border-radius: 500px;
			}
		}
		.install-pwa {
			background: #ffffff;
			height: calc(100vh - 126px) !important;
			padding-bottom: 100px;
			width: 100vw;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: -50px;
			h1 {
				color: $primary;
			}
			h3 {
				font-size: $f4;
				font-weight: 400;
				color: $greyText;
			}
			h1,
			h3 {
				text-align: center;
				width: 100%;
			}
			.share,
			.install {
				margin: auto;
				width: 300px;
				margin-bottom: 0;
				&:hover {
					transform: none;
				}
			}
			.text-btn {
				min-height: 50px;
				font-weight: bold;
				color: $primary;
				text-decoration: underline;
				border-radius: 500px;
			}
		}
		.no-entity {
			max-width: 348px;
			padding: 0 $p6;
			margin: 0 auto;
			height: calc(100% - 126px);
			padding-bottom: 50px;
			h1 {
				color: $text;
				font-weight: 700;
				font-size: $f5;
				text-shadow: 0 0 $text;
			}
			h1 {
				width: 100%;
				text-align: center;
				font-size: $f4;
				color: $text;
			}
			.img-ctn {
				width: 100%;
				height: 162px;
				margin-bottom: $m6;
				img {
					width: 170px;
				}
			}
			.success-message {
				margin-bottom: $m6;
				color: $text;
			}
			.download {
				.primary-btn {
					width: 100%;
				}
			}
		}
		.loader {
			padding: $p6;
			.preloader-inner circle {
				stroke: $text;
			}
			span {
				&.preloader {
					--f7-preloader-color: #014451;
				}
			}
		}
	}
	&.desktop {
		.signup-content {
			.form {
				max-width: 448px;
			}
		}
	}
	&.mobile {
		.signup-content {
			.no-entity {
				overflow-y: scroll;
				overflow-x: hidden;
				-webkit-overflow-scrolling: touch !important;
			}
		}
	}
	.help-cta {
		position: absolute;
		color: white;
		bottom: 14px;
		left: 14px;
		width: 44px;
		height: 44px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #0ea5e9;
		border-radius: 25px;
		box-shadow: $cardShadow;
		cursor: pointer;
	}
}

.resend-btn {
	font-family: $pop !important;
	font-weight: 700;
	font-size: $f4;
	letter-spacing: 0px;
	color: $primary;
	margin-bottom: $m8 !important;
	text-shadow: 0 0 20px #fff;
}

.secondary-btn {
	font-family: $pop !important;
	border-radius: 12px;
	font-weight: 600;
	font-size: $f5;
	letter-spacing: 1px;
	height: 55px;
}

.back-btn {
	font-family: $pop !important;
	font-weight: 700;
	font-size: $f4;
	letter-spacing: 0px;
	color: $white;
}

.terms-popup {
	background: $white;
	.terms-content {
		padding: $p4;
		height: calc(100% - 88px);
		color: $greyText;
		h2 {
			color: $text;
		}
	}
	.btn-ctn {
		padding: $p4;
		.button {
			width: 100%;
		}
	}
}
