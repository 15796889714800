@import '../../css/variables.scss';
.goal-edit-popup {
	font-family: $pop;
	background: $white;
	.react-toggle-track {
		background-color: #8d95a5;
	}
	.react-toggle--checked .react-toggle-track {
		background-color: #1b99fb;
	}
	.react-toggle--checked .react-toggle-track {
		background-color: #1b99fb;
	}
	.react-toggle--checked .react-toggle-thumb {
		border-color: $primary;
	}
	.react-toggle--checked .react-toggle-track:hover {
		background-color: $primary;
	}
	.settings-btn {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #eefaff;
		border: 2px solid #fff;
		border-radius: 50%;
		width: 44px;
		height: 44px;
		i {
			font-size: $f7;
			color: $primary;
		}
	}
	.background {
		// background: linear-gradient(180deg, rgba(26, 156, 255, 0) 85%, $bgGradiantLight 95%, $bgGradiantDark 100%);
		// position: absolute;
		// z-index: -1;
		// width: 100%;
		// height: 100%;
		// &.advanced {
		// 	background: linear-gradient(180deg, rgba(26, 156, 255, 0) 90%, $bgGradiantLight 99%, $bgGradiantDark 100%);
		// }
		// img {
		// 	width: 100%;
		// 	height: 100%;
		// }
	}

	.page-ctn {
		padding: $p4;
		width: 100%;
		height: 100%;
		z-index: 1;

		.title {
			padding-top: $p5;
			font-weight: 600;
			font-size: $f5;
			text-align: center;
			color: $primary;
			margin-bottom: $m7;
		}

		p {
			font-weight: 500;
			font-size: $f2;
			text-align: center;
			color: $text;
			margin-bottom: $m4;
		}

		.smart-abbrv {
			padding: $p6;
			margin-bottom: $m8;
			box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.1);
			border-radius: 12px;
			.hbox {
				margin-bottom: $m4;
				.letter {
					font-family: $pop;
					width: 50px;
					height: 50px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: $white;
					font-size: $f6;
					font-weight: 500;
					border-radius: 50%;
					margin-right: $m4;
					background: radial-gradient(128.13% 792.96% at 99.71% 144.04%, #0061ab 0%, #169aff 41.15%, #38bcf6 84.37%);
				}
				.term {
					font-weight: 600;
					font-size: $f4;
					color: $text;
				}
			}
		}

		.card-btn {
			margin-bottom: $m7;
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
			width: 100%;
			padding: $p4;
			border-radius: $primaryRadius;
			color: $text2;
			font-weight: 600;
			font-size: $f3;
			height: 80px;
			transition: 0.3s;
			background: $white;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			&.ltg {
				justify-content: flex-start;
			}
			&:hover {
				box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
			}
			img {
				height: 60px;
				margin-right: 16px;
			}
		}

		.step-btn {
			border-radius: 20px;
			border: 2px solid $text2;
			color: $text2;
			height: 44px;
			width: 100px;
			padding: $p2 $p7;
			font-weight: 600;
			font-size: $f2;
			background: $white;
		}

		.date-selector {
			color: $primary;
			margin-bottom: $m2;
			input {
				color: $primary;
				text-decoration: underline;
				font-weight: 600;
				width: 120px;
				padding: 16px;
				padding-left: 44px;
				margin-left: -44px;
				&::placeholder {
					color: $primary;
					text-decoration: underline;
					font-weight: 600;
				}
			}
		}

		.cal-icon {
			background-color: #eefaff;
			height: 38px;
			width: 38px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $primary;
			margin-right: $m2;
			i {
				font-size: $f5;
			}
		}
		.input-counter {
			width: 100%;
			height: 16px;
			margin-top: -16px;
			color: $primary;
			font-weight: 500;
			font-size: $f1;
			&.red {
				color: $red-500;
			}
		}
		.basic-input {
			.label {
				align-items: flex-start;
				font-weight: 600;
				font-size: $f4;
				display: flex;
				margin-bottom: $m2;
				margin-left: 0;
			}
		}
		.goal-trigger {
			textarea {
				height: 100px;
			}
		}
		.goal-input {
			textarea {
				height: 250px;
			}
			&.details {
				margin-bottom: 32px;
			}
		}

		.goal-amount-input {
			width: 200px;
		}
		.goal-unit-input {
			margin-left: $m4;
		}

		.info-label {
			h3 {
				font-weight: 600;
				font-size: $f4;
				color: $text;
				margin-bottom: $m2;
			}
			.info-icon {
				width: 30px;
				height: 30px;
				margin: 14px;
				background: #eefaff;
				color: $text;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 600;
				font-size: $f4;
				box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
				z-index: 1;
				cursor: pointer;
				transition: 0.3s;
				&:hover {
					color: #eefaff;
					background: $text;
				}
			}
		}
		.info-icon-other {
			width: 30px;
			height: 30px;
			margin: 14px;
			margin-top: -44px;
			background: #eefaff;
			position: relative;
			left: 120px;
			top: 40px;
			color: $text;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 600;
			font-size: $f4;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
			z-index: 1;
			cursor: pointer;
			transition: 0.3s;
			&:hover {
				color: #eefaff;
				background: $text;
			}
		}
		.value {
			font-weight: 500;
			font-size: 14px;
			color: $primary;
			strong {
				font-weight: 600;
			}
			div {
				margin-top: 8px;
			}
			&.ltg {
				img {
					margin-right: 16px;
					height: 60px;
				}
			}
		}
		.deadline-view {
			margin-bottom: 32px;

			.cta-text {
				color: $text2;
				font-weight: 600;
				font-size: 14px;
			}
		}

		.pair-input {
			.basic-input {
				.label {
					align-items: flex-start;
					font-weight: 500;
					font-size: $f3;
					display: flex;
					margin-bottom: $m2;
					margin-left: 0;
				}
			}
			.trash-placeholder {
				width: 60px;
			}
			.trash-placeholder,
			.bx-trash {
				color: $text;
				font-size: $f6;
				margin-left: $m4;
				padding: $p2;
				top: -12px;
				position: relative;
			}
		}
		.toggle-ctn {
			color: $text2;
			font-weight: 600;
			font-size: $f3;
			&.repeatable {
				margin-bottom: $m8;
			}
		}
		.message {
			font-weight: 600;
			font-size: $f2;
			color: $text;
			margin-bottom: $m2;
			&.small {
				min-width: 300px;
			}
		}

		.date-card {
			padding: $m4;
			box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.1);
			border-radius: 12px;
			margin-bottom: $m8;
			.selection {
				cursor: pointer;
				padding: $m4 0;
				border-bottom: 1px solid $gray-200;
				&:last-child {
					border: none;
				}
				.day {
					font-weight: 600;
					font-size: $f2;
					color: $text2;
					margin-right: $m4;
				}
				.date {
					color: $greyText;
					font-size: $f2;
				}
				.checkbox-ctn {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 24px;
					height: 24px;
					border-radius: 50%;
					border: 1px solid $text2;
					box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
					transition: 0.3s;
					i {
						font-size: $f6;
						color: $white;
					}
				}
				&.checked {
					.checkbox-ctn {
						border-color: transparent;
						background: radial-gradient(128.13% 792.96% at 99.71% 144.04%, #0061ab 0%, #169aff 41.15%, #38bcf6 84.37%);
					}
				}
			}
		}
		.start-date-ctn,
		.end-date-ctn {
			padding: $p4 0;
			border-top: 1px solid $gray-200;
			.message {
				font-size: $f2;
				color: $text2;
			}
			.date {
				color: $greyText;
			}
			.bx-trash {
				margin-left: $m1;
				font-size: $f6;
				color: $text;
				padding: $p2;
			}
		}

		.cadence-picker {
			.day-icon {
				width: 44px;
				height: 44px;
				border-radius: 50%;
				color: $greyText;
				border: 1px solid $greyText;
				text-transform: uppercase;
				transition: 0.3s;
				margin-right: $m1;
				margin-top: $m4;
				font-size: $f4;
				&.selected {
					background-color: $text2;
					color: $white;
					border-color: $text2;
				}
			}
		}

		.divider-line {
			height: 1px;
			width: 100%;
			background: #e5e5e5;
			margin-bottom: $m6;
		}

		.impact-text {
			font-weight: 600;
			font-size: $f3;
			color: $text2;
			margin-bottom: $m8;
		}
		.rc-slider {
			margin-bottom: $m8;
			width: 300px;
		}

		.spacer {
			height: 32px;
		}
		.btn-ctn {
			width: 100%;
		}

		.add-btn {
			padding: $p4 0;
			margin-bottom: $m4;
			font-weight: 600;
			color: $text2;
			.plus-icon {
				width: 32px;
				height: 32px;
				margin-right: 14px;
				background: #eefaff;
				color: $text;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
				z-index: 1;

				i {
					font-size: $f5;
				}
			}

			transition: 0.3s;
			&:hover {
				cursor: pointer;
				.plus-icon {
					color: #eefaff;
					background: $text;
				}
			}
		}

		.help-overlay {
			background: $white;
			border-radius: 12px;
			.close {
				font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
				width: 32px;
				height: 32px;
				margin: 14px;
				background: #eefaff;
				color: $text;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
				z-index: 1;
				cursor: pointer;
				transition: 0.3s;
				&:hover {
					color: #eefaff;
					background: $text;
				}
				i {
					font-size: $f3;
				}
			}
			p {
				text-align: left;
				margin-bottom: $m8;
			}
			.number-list {
				white-space: pre;
				text-align: left;
				word-wrap: break-word;
			}
			.example-btn {
				text-transform: capitalize;
				border-radius: 12px;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 32px;
				i {
					font-size: $f4;
					margin-left: $m4;
				}
			}
			.example-goal {
				margin-bottom: 50px;
				border-radius: 12px;
				h3 {
					font-weight: 600;
					font-size: $f4;
					color: $text;
				}
				.goal-card {
					border-radius: 12px;
					box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.1);
					padding: $p4;
					h3 {
						font-weight: 600;
						font-size: 16px;
						color: $text2;
						margin-bottom: $m2;
					}
					.divider-line {
						height: 1px;
						width: 100%;
						background: #e5e5e5;
						margin-bottom: $m6;
					}
					.value {
						font-weight: 500;
						font-size: 14px;
						color: $primary;
						strong {
							font-weight: 600;
						}
						div {
							margin-top: 8px;
						}
						&.ltg {
							img {
								margin-right: 16px;
								height: 60px;
							}
						}
					}
					p {
						margin-bottom: $m4;
						margin-top: 0;
						font-weight: 500;
						font-size: $f3;
					}
				}
			}
		}

		.btn-ctn {
			width: 100%;
			.button {
				width: 240px;
				margin-bottom: 16px;
				text-transform: none;
			}
		}

		.success-screen {
			color: $white;
			text-align: center;
			padding: $p4;
			padding-top: 44px;
			z-index: 1;
			h1 {
				margin: 0;
				font-weight: 600;
				font-size: $f7;
				margin-bottom: $m9;
			}
			.date {
				margin-bottom: $m4;
				text-transform: capitalize;
				font-weight: 600;
			}
			.goal-text {
				margin-bottom: $m8;
				background: #dfecf8;
				padding: $p4;
				border-radius: $primaryRadius;
				color: $primary;
				text-align: left;
				word-wrap: break-word;

				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
				img {
					width: 14px;
					margin-right: $m2;
					margin-top: -2px;
				}
				.goal {
					margin-bottom: $m2;
					font-size: 22px;
					font-weight: 600;
				}
			}
			h3 {
				margin-bottom: $m4;
				font-weight: 600;
				font-size: $f5;
			}
			.glass-btn {
				margin-bottom: $m6;
				background: #75a9da;
				border-radius: 500px;
				height: 44px;
				border: 2px solid $white;
				color: $white;
				font-weight: 600;
				font-size: $f2;
				width: 280px;
				text-transform: none;
			}
		}

		.advanced-flow {
			.toggle-ctn {
				margin-bottom: 32px;
			}
			.section-divider {
				height: 1px;
				width: 100%;
				background: #e5e5e5;
				margin: 13px 0;
			}
			.info-label {
				margin-bottom: 16px;
			}
			.mb-100 {
				margin-bottom: 100px;
			}
			&.step-3 {
				.title {
					margin-bottom: -32px;
				}
				h2 {
					font-size: 22px;
					font-weight: 600;
					color: $text;
					margin-bottom: $m2;
				}
				.yes-no {
					.card-btn {
						&:last-child {
							margin-left: 16px;
						}
						&.selected {
							border: 2px solid $text2;
						}
					}
				}
			}
		}
	}
}
