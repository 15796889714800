@import '../../css/variables.scss';
.thought-details-popup {
	font-family: $pop;
	background: $white;
	.content-ctn {
		padding: $p4;
		animation-duration: 0.3s;
		height: 100%;
		.ari-message {
			margin-bottom: 32px;
		}
		.anxious-toggle {
			border: 2px solid $text2;
			text-align: center;
			width: 250px;
			border-radius: $primaryRadius;
			height: 32px;
			color: $text2;
			font-weight: 600;
			margin: 0 $m8 $m8 $m8;
			i {
				font-size: $f7;
				margin-top: -4px;
			}
		}
		.header-ctn {
			padding: $p4 0;
			.title {
				font-size: $f5;
				font-weight: 600;
				color: $white;
				text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.55);
				img {
					width: 44px;
					margin-right: $m4;
				}
			}
			.settings-btn {
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #eefaff;
				border: 2px solid #fff;
				border-radius: 50%;
				width: 44px;
				height: 44px;
				i {
					font-size: $f7;
					color: $primary;
				}
			}
		}
		h1 {
			letter-spacing: -1px;
			text-align: center;
			margin: 0;
			font-weight: 600;
			font-size: $f5;
			margin-bottom: $m8;
		}

		.thought-card {
			padding: $p4;
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
			border-radius: $primaryRadius;
			margin-bottom: $m8;
			background: $white;
			cursor: pointer;
			min-height: 60px;
			transition: 0.4s;

			.date {
				margin-right: $m1;
				padding-right: $p2;
				.day {
					font-weight: 500;
					color: $text2;
					font-size: $f1;
					margin-bottom: -4px;
				}
				.daynum {
					font-weight: 600;
					font-size: $f6;
					color: $primary;
					margin-bottom: -5px;
				}
				.month {
					font-weight: 500;
					color: $primary;
					font-size: $f1;
				}
			}
			.card-content {
				border-left: 1px solid $gray-200;
				padding-left: $p4;
				margin-bottom: $m8;
			}
			.priority {
				min-width: 44px;
				height: 50px;
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.thought-body {
				margin-bottom: $m4;
				padding-bottom: $p4;
				border-bottom: 1px solid $gray-200;
				color: $primary;
				font-weight: 500;
				.text-body {
					width: 100%;
					margin-right: 20px;
					word-break: break-all;
					max-width: 400px !important;
				}
				.thought-type {
					margin-bottom: $m2;
					color: $white;
					font-weight: 600;
					font-size: $f1;
					text-transform: uppercase;
					padding: 2px;
					text-align: center;
					border-radius: 500px;
					max-width: 175px !important;
					display: flex;
					justify-content: center;
					align-items: center;
					&.anxious {
						background: #ef4444;
					}
					&.positive {
						background: $text2;
					}
					&.reframed {
						background: #84cc16;
					}
				}
			}
			.thought-detail {
				margin-bottom: $m4;
				padding-bottom: $p4;
				border-bottom: 1px solid $gray-200;
				color: $primary;
				font-weight: 500;
				&:last-child {
					border: none;
				}
				.label {
					font-weight: 600;
					color: $text2;
					margin-bottom: $m2;
					text-transform: uppercase;
				}
				.text-body {
					width: 100%;
					word-break: break-all;
					max-width: 210px;
					.category {
						margin-left: -16px;
					}
				}
			}
			.priority-ctn {
				img {
					width: 16px;
					margin-right: $m2;
				}
				.toggle-text {
					font-weight: 600;
					color: $text2;
				}
			}
			&.reframed {
				display: flex;
				padding-bottom: 0;
				.card-content {
					margin-bottom: $m4;
				}
				.thought-body,
				.thought-detail {
					border: none;
					margin-bottom: 0;
				}
			}
		}

		.spacer {
			height: 50px;
		}
	}

	&.desktop {
		.page-content {
			overflow-y: scroll;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch !important;
		}
		.content-ctn {
			max-width: 630px;
			margin-left: auto;
			margin-right: auto;
			.thought-card {
				.thought-body {
					width: 100%;
					.text-body {
						width: 100%;
						margin-right: 20px;
						word-break: break-all;
						max-width: 400px !important;
					}
				}
			}
			.text-body {
				width: 100%;
				margin-right: 20px;
				word-break: break-all;
				max-width: 400px !important;
			}
		}
	}
}
