@import '../../css/variables';
.bvr-nav-toolbar {
	height: 75px;
	background: rgba($color: #ffffff, $alpha: 1) !important;

	.active-tab-bar {
		background: $text2;
		height: 4px;
		border-radius: 500px;
		width: calc(calc((100vw / 5)) - 10px);
		position: absolute;
		top: 0px;
		transition: 0.2s;
		&.home {
			left: 10px;
		}
		&.progress {
			left: calc((100vw / 5) + 10px);
		}
		&.goals {
			left: calc((100vw / 5) * 3);
		}
		&.thoughts {
			left: calc((100vw / 5) * 4);
		}
	}
	.action {
		cursor: pointer;
		img {
			height: 24px;
			margin-bottom: 6px;
		}
		.text {
			font-size: 10px;
			color: $text;
			font-weight: 500;
			font-family: $pop;
		}
		.fillme {
			fill: $text;
		}
		.path {
			stroke: $text;
		}
		&:hover,
		&.active {
			.path {
				stroke: $text2;
			}
			.fillme {
				fill: $text2;
			}
			.text {
				color: $text2;
			}
		}
		.nav-add-btn {
			background: #bce9fe;
			width: 44px;
			height: 44px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			font-size: 44px;
			font-weight: 200;
			color: $text;
			box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
			transition: 0.3s;
			&:hover {
				box-shadow: none;
			}
		}
	}
}

.bvr-creation-sheet-cmp {
	border-radius: 12px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	height: auto;
	font-family: $pop;
	.create-sheet-content {
		padding: $p6 $p3 $p6 $p6;
		.create-header {
			font-weight: 600;
			font-size: $f3;
			color: $text;
			.close-btn {
				cursor: pointer;
				background: #eefaff;
				margin: 12px;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				i {
					font-size: $f6;
				}
				&:hover {
					color: $white;
					background: $primary;
				}
			}
		}
		.locked-message {
			color: $text;
			h1 {
				margin: 0;
			}
			p {
				text-align: center;
				font-weight: 500;
				font-size: $f2;
				padding: 0 50px;
			}
		}
		.sheet-action {
			font-family: $pop;
			padding: $p4;
			font-weight: 600;
			font-size: $f3;
			color: $text2;
			cursor: pointer;
			transition: 0.2s;
			.icon {
				width: 36px;
				height: 36px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #eefaff;
				margin-right: $m4;
				img {
					height: 20px;
				}
			}
			&:hover {
				background: #eefaff;
				border-radius: $primaryRadius;
			}
		}
	}
	&.mobile {
		.sheet-modal-inner {
			overflow-y: scroll;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch !important;
		}
	}
	&.desktop {
		max-width: 630px;
		margin-left: calc((100vw - 630px) / 2);
		margin-top: $m10;
		border-radius: 12px;
		&.modal-out {
			margin-top: 0;
		}
	}
}
