@import '../../css/variables';

.communication-page-popup {
	background: $white;
	.popup-header {
		width: 100%;
		padding: $p4;
		height: 87px;
		.back-btn {
			color: $primary;
			font-weight: bold;
			font-size: $f4;
			i {
				margin-right: $m1;
				font-size: $f6;
			}
		}
		img {
			width: 200px;
			right: -75px;
			position: absolute;
		}
	}
	.main-content {
		padding: $p4;
		padding-top: 0;
		height: calc(100% - 87px);
		h1 {
			color: $primary;
			margin-top: 0;
			font-weight: 600;
			font-size: $f7;
			margin-bottom: $m4;
			padding-right: 100px;
		}
		p {
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			color: #64748b;
			margin-bottom: $m8;
		}
		.product-preferences {
			.toggle-ctn {
				margin-bottom: $m8;
				.toggle-text {
					color: $primary;
					font-size: $f3;
					font-weight: 600;
				}
				.react-toggle-track {
					background-color: #8d95a5;
				}
				.react-toggle--checked .react-toggle-track {
					background-color: #1b99fb;
				}
				.react-toggle--checked .react-toggle-track {
					background-color: #1b99fb;
				}
				.react-toggle--checked .react-toggle-thumb {
					border-color: $primary;
				}
			}
		}
	}
}
