@import '../../css/variables';
.install-pwa-popup {
	padding: 16px;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 55%, $bgGradiantLight 80%, $bgGradiantDark 94%);
	.popup-header {
		width: 100%;

		a {
			color: $primary !important;
		}
	}
	height: calc(100vh) !important;
	padding-bottom: 100px;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		color: $primary;
	}
	h3 {
		font-size: $f4;
		font-weight: 400;
		color: $greyText;
	}
	h1,
	h3 {
		text-align: center;
		width: 100%;
	}
	.share,
	.install {
		margin: auto;
		width: 300px;
		margin-bottom: 0;
		&:hover {
			transform: none;
		}
	}
	.text-btn {
		min-height: 50px;
		font-weight: bold;
		color: $primary;
		text-decoration: underline;
		border-radius: 500px;
	}
}
