@import '../../css/variables.scss';
.home-page {
	font-family: $pop;
	background: $white;
	.background {
		background: linear-gradient(180deg, rgba(26, 156, 255, 0) 15.1%, $bgGradiantLight 56.91%, $bgGradiantDark 100%);
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		transition: 0.5s;
	}
	.home-content {
		height: 100%;
		.homepage-header {
			padding: $p4;
			margin-bottom: $m6;
			height: 76px;
			.icon-logo {
				max-height: 31px;
			}
			.signout-btn {
				color: $primary;
				height: 44px;
				text-transform: capitalize;
				font-weight: bold;
				i {
					margin-left: $m1;
					font-size: $f5;
				}
			}
		}
		.card-list {
			height: calc(100% - 100px);
			padding: $p4;
			.product-card {
				margin-bottom: $m8;
				.product-background {
					border-radius: 22px;
					width: calc(100% - 64px);
					margin-left: auto;
					margin-right: auto;
					margin-bottom: -74px;
					box-shadow: 0px 0px 15px rgba(0, 97, 171, 0.28);
				}
				.title-card {
					width: 100%;
					padding: $p4;
					height: 100px;
					background: rgba(255, 255, 255, 0.25);
					border: 1px solid #ffffff;
					backdrop-filter: blur(50px);
					-webkit-backdrop-filter: blur(50px);
					border-radius: 12px;
					text-shadow: 0px 0px 15px #ffffff;
					.details {
						.setup-warning {
							color: $red-500;
							background: white;
							padding: $p1 $p2;
							border-radius: 8px;
							box-shadow: 0 0 10px rgba(7, 7, 135, 0.372);
							position: absolute;
							top: -15px;
							font-weight: bold;
							i {
								font-size: $f6;
								margin-right: $m1;
							}
						}
					}
					.title {
						color: $primary;
						font-size: $f6;
						font-weight: bold;
					}
					.subtitle {
						color: $primary;
						font-size: $f2;
						font-weight: bold;
						.bx-check-circle {
							font-size: $f4;
							margin-right: $m1;
						}
					}

					.bx-right-arrow-alt {
						color: $white;
						margin-left: $m2;
						font-size: $f7;
						text-shadow: 0px 0px 5px rgba(0, 97, 171, 0.82);
					}
				}
				transition: 0.3s;
				&:hover {
					.title-card {
						background: rgba(255, 255, 255, 0.45);
					}
				}
			}
			.account-card {
				width: 100%;
				padding: $p6;
				height: 100px;
				margin-bottom: $m8;
				background: rgba(255, 255, 255, 0.25);
				border: 1px solid #ffffff;
				backdrop-filter: blur(50px);
				-webkit-backdrop-filter: blur(50px);
				border-radius: 12px;
				color: $white;
				font-size: $f4;
				font-weight: bold;
				text-shadow: 0px 0px 5px rgba(0, 97, 171, 0.82);
				.bxs-truck {
					font-size: $f8;
					margin-right: $m1;
					margin-top: 3px;
				}
				.bx-heart,
				.bx-lock,
				.bx-user-circle {
					font-size: $f6;
					margin-right: $m1;
					margin-top: 3px;
				}
				.bx-lock {
					margin-top: 0px;
				}
				.bx-right-arrow-alt {
					margin-left: $m2;
					font-size: $f7;
				}
				.message-text {
					p {
						font-size: $f2;
						margin: 0;
					}
				}
				transition: 0.3s;
				&:hover {
					background: rgba(255, 255, 255, 0.45);
				}
			}
		}
	}
	&.desktop {
		.home-content {
			.card-list {
				.product-card {
					.product-background {
						transition: 0.3s;
					}
					&:hover {
						.product-background {
							transform: scale(0.9);
						}
					}
				}
				.product-card,
				.account-card {
					max-width: 450px;
					cursor: pointer;
				}
				.account-card {
					&:hover {
						transform: scale(0.95);
					}
				}
			}
		}
	}
}
