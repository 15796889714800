@import '../../css/_variables.scss';
.basic-input {
	width: 100%;
	margin-bottom: 16px;
	position: relative;
	$inputHeight: 44px;
	&.date-picker-input {
		input {
			line-height: $inputHeight;
		}
	}
	.label {
		font-family: $pop;
		color: $primary;
		font-size: 14px;
		margin-bottom: 3px;
		margin-left: 16px;
	}
	.input-icon-holder {
		position: absolute;
		z-index: 9;
		right: 12px;
		bottom: 0;
		width: 44px;
		height: $inputHeight;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
	}
	input,
	textarea {
		width: 100%;
		font-size: 16px;
		height: $inputHeight;
		background: #ffffff;
		box-shadow: inset 5px 4px 7px rgba(8, 73, 122, 0.21);
		border-radius: 10px;
		padding: 0 24px;
		color: #1e293b;
		&:disabled {
			background: #e2e8f0;
			box-shadow: none;
			content: '';
		}
		&::placeholder {
			color: #9ca3af;
			font-size: $f2;
		}
	}

	input[type='tel'] {
		height: 40px !important;
		width: calc(100% - 4px);
	}

	textarea {
		min-height: 75px;
		padding: 16px;
		border-radius: 12px;
	}
	.input {
		&::after {
			display: none !important;
		}
	}
	.read-only {
		input {
			color: $greyText;
		}
	}
	.error-message {
		margin-top: 8px;
		margin-left: 24px;
		color: $red-500;
	}
	&.resizable {
		textarea {
			resize: vertical;
		}
	}
	&.verification {
		input {
			width: 100%;
		}
	}
	.gradiant {
		background: linear-gradient(90deg, $buttonLight 0%, $buttonDark 100%);
		border-radius: 12px;
		height: 44px;
		display: flex;
		justify-content: center;
		align-items: center;
		.input {
			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				background: #ffffff !important;
				box-shadow: inset 5px 4px 7px rgba(8, 73, 122, 0.21);
				border-radius: 200px;
			}
			width: calc(100% - 4px);
			input {
				height: 40px !important;
			}
		}
		.input-error-message {
			visibility: hidden;
			height: 0 !important;
			margin: 0 !important;
			padding: 0;
		}
		&.invalid {
			background: $red-500 !important;
			input {
				color: $red-500 !important;
			}
		}
	}
	&.textarea {
		textarea {
			border-radius: 10px;
		}
		.gradiant {
			height: auto;
			border-radius: 12px;
			border-top: 2px solid $primary;
			border-bottom: 2px solid $primary;
		}
	}
}
