@import '../../css/variables';
.donation-page-popup {
	background: linear-gradient(180deg, $bgGradiantLight 56.91%, $bgGradiantDark 100%);
	.popup-header {
		width: 100%;
		padding: $p4;
		height: 87px;
		.back-btn {
			color: $white;
			font-weight: bold;
			font-size: $f4;
			i {
				margin-right: $m1;
				font-size: $f6;
			}
		}
		img {
			width: 55px;
		}
	}
	.main-content {
		padding: $p4;
		padding-top: 0;
		height: calc(100% - 87px);
		h1 {
			color: $white;
			margin-top: 0;
			font-weight: 600;
			font-size: $f7;
		}
		p {
			color: $white;
			font-size: $f2;
			font-weight: bold;
			margin-bottom: $m8;
		}
		.primary-btn {
			margin-bottom: $m8;
			&.white {
				background: $white;
				color: $primary;
				box-shadow: 0px 0px 20px rgba(54, 181, 240, 0.69);
			}
		}
		.save-moms {
			margin: $m8;
			width: 150px;
			cursor: pointer;
		}
	}
	&.desktop {
	}
}
