@import '../../css/variables';
.product-page-popup {
	.popup-header {
		overflow: hidden;
		.image-container {
			width: 100%;
			position: absolute;
			top: -54px;
			z-index: 0;
			overflow: hidden;
			height: 250px;
			.header-image {
				width: 100%;
			}
		}

		.controls {
			width: 100%;
			padding: $p4;
			margin-bottom: $m4;
			height: 87px;
			.back-btn {
				color: $primary;
				font-weight: bold;
				font-size: $f4;
				i {
					margin-right: $m1;
					font-size: $f6;
				}
			}
			img {
				width: 55px;
				z-index: 999;
			}
		}
	}
	.main-content {
		z-index: 1;
		background: linear-gradient(180deg, $bgGradiantLight 56.91%, $bgGradiantDark 100%);
		height: calc(100% - 102px);
		.title-card {
			width: 100%;
			padding: $p4;
			height: 100px;
			background: rgba(255, 255, 255, 0.55);
			border: 1px solid #ffffff;
			backdrop-filter: blur(50px);
			-webkit-backdrop-filter: blur(50px);
			text-shadow: 0px 0px 15px #ffffff;
			border-bottom-left-radius: 12px;
			border-bottom-right-radius: 12px;
			.title {
				color: $primary;
				font-size: $f6;
				font-weight: bold;
			}
			.subtitle {
				color: $primary;
				font-size: $f2;
				font-weight: bold;
			}

			.bx-right-arrow-alt {
				color: $white;
				margin-left: $m2;
				font-size: $f7;
				text-shadow: 0px 0px 5px rgba(0, 97, 171, 0.82);
			}
		}
		.product-description {
			color: $primary;
			margin: $m4;
			background: rgba(255, 255, 255, 0.6);
			border: 1px solid #ffffff;
			-webkit-backdrop-filter: blur(50px);
			backdrop-filter: blur(50px);
			border-radius: 12px;
			height: calc(100% - 235px);
			p {
				font-size: $f2;
				margin: 0;
				padding: $p4;
			}
			ul {
				margin: $m1 0;
			}
		}
		.btn-ctn {
			padding: $p4;
			margin-bottom: $m4;
		}
	}
	&.desktop {
		.popup-header {
			.image-container {
				top: -134px;
				height: 330px;
				.header-image {
					width: 100%;
				}
			}
		}
	}
}
